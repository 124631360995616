import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NotificationTypes } from '../enum/notification.enum';

export interface INotification {
  id: number;
  message?: string;
  type?: NotificationTypes;
  data?: unknown;
}

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private notificationsSubject = new BehaviorSubject<INotification[]>([]);
  public notifications$ = this.notificationsSubject.asObservable();

  private idCounter = 0;

  addNotification(message?: string, type?: NotificationTypes, data?: unknown) {
    const notification: INotification = {
      id: ++this.idCounter,
      message,
      type,
      data
    };
    const currentNotifications = this.notificationsSubject.getValue();
    this.notificationsSubject.next([...currentNotifications, notification]);

    // when updating the timeout here, also update the set timeout in remove() in notification.component.ts
    setTimeout(() => this.removeNotification(notification.id), 5000);
  }

  removeNotification(id: number) {
    const currentNotifications = this.notificationsSubject.getValue();
    this.notificationsSubject.next(currentNotifications.filter((notification) => notification.id !== id));
  }
}
