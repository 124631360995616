export enum NotificationTypes {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  CART = 'cart'
}

export enum NotificationClass {
  SUCCESS = 'notification__success-bg',
  ERROR = 'notification__error-bg',
  WARNING = 'notification__warning-bg'
}

export enum NotificationSidebarClass {
  SUCCESS = 'notification__sidebar-success',
  ERROR = 'notification__sidebar-error',
  WARNING = 'notification__sidebar-warning'
}

export enum NotificationIcon {
  SUCCESS = 'icon-success',
  WARNING = 'icon-warning',
  ERROR = 'icon-error'
}
